<template>
  <CRow>
    <CCol lg="12">
      <form id="formUpdateProfile" v-on:submit.prevent="updateProfile" method="POST">
        <CCard v-if="stdData">
          <CCardHeader>
            <div class="text-center" style="height: 100px">
              <img src="../assets/Avatar.png" class="c-avatar-img" style="height: 60%; width: auto" />
              <div>{{ stdData.stud_id }}</div>
              <div>
                <strong>{{ stdData.prefix_name }}{{ stdData.std_name }}
                  {{ stdData.std_surname }}</strong>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="2" style="margin-top: 20px"><strong>#:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px"><input type="text" name="stdDataId" class="form-control"
                  v-model="stdData.std_dt_id" readonly /></CCol>
              <CCol lg="6" style="margin-top: 20px"></CCol>



              <CCol lg="6" style="margin-top: 20px" v-if="userData.user_address">
                <SltLevel />
              </CCol>
              <CCol lg="6" style="margin-top: 20px" v-if="userData.user_address">
                <SltClass />
              </CCol>

              <CCol lg="2" style="margin-top: 20px"><strong>ชื่อผู้ปกครอง: </strong></CCol>
              <CCol lg="4" style="margin-top: 20px"><input type="text" name="parentName" class="form-control"
                  v-model="stdData.std_parent_name" required /></CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>ความสัมพันธ์:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px" v-if="relationshipInfo">
                <select name="relationship" class="form-control" v-model="stdData.std_parent_relationship" required>
                  <option v-for="re in relationshipInfo" :key="re.id" :value="re.relationship_id">
                    {{ re.relationship_name }}
                  </option>
                </select>
              </CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>บ้านเลขที่:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px"><input type="text" name="address" class="form-control"
                  v-model="stdData.std_address" /></CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>หมู่บ้าน:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px"><input type="text" name="village" class="form-control"
                  v-model="stdData.std_village" required /></CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>ตำบล:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px">
                <SltDistrict />
              </CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>เบอร์โทรนักเรียน:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px"><input type="text" name="tel" class="form-control"
                  v-model="stdData.std_tel" /></CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>เบอร์โทรผู้ปกครอง:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px"><input type="text" name="parentTel" class="form-control"
                  v-model="stdData.std_ptel" /></CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>email นักเรียน:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px"><input type="email" name="email" class="form-control"
                  v-model="stdData.std_email" /></CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>เพื่อนสนิท:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px">
                <SltStudent />
              </CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>โรคประจำตัว:</strong></CCol>
              <CCol lg="4" style="margin-top: 20px">
                <input type="text" name="stdCD" class="form-control" v-model="stdData.std_CD" />
              </CCol>
              <CCol lg="2" style="margin-top: 20px"><strong>ตำแหน่งที่ตั้งของบ้าน/ หอพัก ปัจจุบัน:</strong></CCol>
              <CCol lg="10" style="margin-top: 20px">
                <div v-if="!stdData.std_lat" class="alert alert-danger">
                  ข้อมูล Location ยังไม่อัพเดท
                  โปรดเลื่อนหมุดไปยังตำแหน่งที่ตั้งที่ถูกต้องแล้วกด save
                </div>
                <div v-if="errorStr">{{ errorStr }}</div>
                <div v-if="location">
                  <GmapMap :center="centerPosition" :zoom="15" map-type-id="terrain" style="width: 100%; height: 300px">
                    <GmapMarker :position="markerPointer.position" :clickable="true" :draggable="false"
                      @dragend="updatePosition" @click="clickToNavigate()" />
                  </GmapMap>
                </div>
              </CCol>
              <CCol lg="2" style="margin-top: 20px"> </CCol>
              <CCol lg="5" style="margin-top: 20px">
                Latitude:
                <input type="text" name="txtLat" class="form-control" v-model="pinnedPosition.lat" readonly />
              </CCol>
              <CCol lg="5" style="margin-top: 20px">
                Longitude:
                <input type="text" name="txtLng" class="form-control" v-model="pinnedPosition.lng" readonly />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter class="text-right" v-if="userData.user_level.search('adminHR') >= 0">
            <button type="submit" class="btn btn-warning">save</button>
          </CCardFooter>
        </CCard>
      </form>
    </CCol>
  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx
import SltDistrict from "./components/SltDistrict";
import SltStudent from "./components/SltStudent.vue";
import SltClass from "./components/SltClass.vue";
import SltLevel from "./components/SltLevel.vue";
import usersData from "./users/UsersData";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    Datepicker,
    SltDistrict,
    SltStudent,
    SltClass,
    SltLevel
  },
  data() {
    return {
      darkModal: false,
      exportingData: null,
      teacherInfo: null,
      nowMonth: null,
      nowYear: null,
      userData: this.$cookies.get("user"),
      selectedDate: null,
      thMonth: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      relationshipInfo: null,
      centerPosition: { lat: 0.0, lng: 0.0 },
      markerPointer: { position: { lat: null, lng: null } },
      markers: [{ position: { lat: 10.2, lng: 10.2 } }],
      pinnedPosition: { lat: null, lng: null },
      location: null,
      gettingLocation: false,
      errorStr: null,
      stdData: null,
    };
  },
  created() {
    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }

    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
        /*
        alert("กำลัง redirect ไปยังเว็บที่สามารถใช้งาน location ได้");
        location.replace(
          "https://academic.skw.ac.th/stdAttendance/userProfile"
        );
        */
      }
    );
  },
  mounted() {
    //console.log(this.userData);
    //FETCH STUDENT DATA
    this.axios
      .get(
        this.$hostUrl +
        "php_action/userAPI.php?txtAction=getStdData&stdId=" +
        this.$route.params.stdId +
        "&schoolCode=" +
        this.$route.params.schoolCode
      )
      .then((response) => {
        console.log(response.data);
        this.stdData = response.data.mainData;
        //GET LOCATION
        if (this.stdData.std_lat) {
          this.centerPosition.lat = parseFloat(this.stdData.std_lat);
          this.centerPosition.lng = parseFloat(this.stdData.std_lng);
        } else {
          navigator.geolocation.getCurrentPosition(this.showPosition);
        }

        this.markerPointer.position = this.centerPosition;
        this.pinnedPosition = this.centerPosition;

        if (this.stdData.std_district != 0 || this.stdData.std_district != 99) {
          this.$store.commit("setSelectedDistrict", {
            code: this.stdData.DISTRICT_CODE,
            label: this.stdData.DISTRICT_NAME + " " + this.stdData.AMPHUR_NAME,
          });
        }
        this.$store.commit("setSelectedClass", this.stdData.std_class);
        this.$store.commit("setSelectedLevel", {code: this.stdData.std_level_code, label: this.stdData.level_abv_name});

        if (this.stdData.std_close_friends != "") {
          this.$store.commit(
            "setSelectedStudent",
            JSON.parse(this.stdData.std_close_friends)
          );
        }
      });

    //FETCH RELATIONSHIP DROPDOWN
    this.axios
      .get(
        this.$hostUrl +
        "php_action/relationshipAPI.php?txtAction=getAllRelationship"
      )
      .then((response) => {
        //console.log(response.data);
        this.relationshipInfo = response.data.mainData;
        //console.log(this.relationshipInfo);
      });
  },
  methods: {
    updateProfile(event) {
      //SEND DATA
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "updateStudent");
      formData.append(
        "closeFriends",
        JSON.stringify(this.$store.state.selectedStudent)
      );
      //console.log(this.$store.state.selectedDistrict);

      if (!this.$store.state.selectedDistrict.code) {
        alert("กรุณาระบุจังหวัดเพื่อเลือกตำบล");
        return false;
      }
      formData.append("district", this.$store.state.selectedDistrict.code);

      if (this.userData.user_address) {
        formData.append("userId", this.userData.user_id);
      } else {
        formData.append("userId", this.userData.stud_id);
      }
      formData.append("stdClass", this.$store.state.selectedClass);
      formData.append("stdLevel", this.$store.state.selectedLevel.code);
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "updated") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    updateTableForTeacher() {
      let teacherId = this.selectedTeacher.code;

      //FETCH Semester Stats BY TEACHER
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=getStatByTeacher&selectedSemester=" +
          this.$route.params.semesterId +
          "&teacherId=" +
          teacherId
        )
        .then((response) => {
          this.rows = response.data.mainData;
          if (this.rows == undefined) {
            this.rows = [{}];
          }
        })
        .finally(() => { });
    },
    getReasonStat(data) {
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=getStatByReason&schoolId=" +
          this.userData.user_address +
          "&reasonId=" +
          data
        )
        .then((response) => {
          //console.log(response.data);
          this.rows = response.data.mainData;
          //console.log(this.rows);
        })
        .finally(() => { });
    },
    getSelectedMonth(dataDate) {
      let d = new Date();
      if (dataDate) {
        d = new Date(dataDate);
      }
      this.nowYear = d.getFullYear() + 543;
      //alert(d.toISOString());
      //alert(d.getMonth());
      this.nowMonth = this.thMonth[d.getMonth()];
    },
    showPosition(position) {
      console.log(position);
      //alert(position.coords.accuracy);
      //console.log(position.coords.latitude);
      //console.log(position.coords.longitude);
      this.centerPosition.lat = position.coords.latitude;
      this.centerPosition.lng = position.coords.longitude;
      //this.markers[0].position.lat = this.centerPosition.lat;
      //this.markers[0].position.lng = this.centerPosition.lng;
    },
    updatePosition(event) {
      this.pinnedPosition.lat = event.latLng.lat();
      this.pinnedPosition.lng = event.latLng.lng();
    },
    clickToNavigate() {
      window.open(
        "https://www.google.com/maps/dir//" +
        this.centerPosition.lat +
        "," +
        this.centerPosition.lng
      );
    },
  },
};
</script>

<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
